<template>
  <v-layout v-if="getPermission('service:create')">
    <v-col class="custom-content-container pt-0" cols="12" md="9" sm="9">
      <div v-if="errors.length" class="iv-custom-field pt-0 width-100">
        <div
          class="alert fade alert-danger"
          role="alert"
          v-bind:class="{ show: errors.length }"
        >
          <div v-for="(error, i) in errors" :key="i" class="alert-text">
            {{ error }}
          </div>
        </div>
      </div>
      <!-- <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      > -->
      <v-col md="8" offset-md="2" class="pb-0">
        <v-text-field
          dense
          filled
          label="Name"
          solo
          flat
          color="cyan"
          v-model.trim="productCreate.name"
          :rules="[
            validateRules.required(productCreate.name, 'Name'),
            validateRules.minLength(productCreate.name, 'Name', 1),
            validateRules.maxLength(productCreate.name, 'Name', 100),
          ]"
        ></v-text-field>
        <v-textarea
          dense
          filled
          label="Description"
          solo
          flat
          row-height="20"
          auto-grow
          color="cyan"
          v-model.trim="productCreate.description"
          :rules="[
            validateRules.minLength(productCreate.description, 'Name', 1),
            validateRules.maxLength(productCreate.description, 'Name', 1024),
          ]"
        ></v-textarea>
        <div class="d-flex">
          <v-text-field
            dense
            filled
            color="cyan"
            label="Reference"
            solo
            flat
            :rules="[
              validateRules.minLength(productCreate.reference, 'Reference', 1),
              validateRules.maxLength(
                productCreate.reference,
                'Reference',
                100
              ),
            ]"
            class="pr-2 width-100"
            v-model.trim="productCreate.reference"
          ></v-text-field>
          <v-text-field
            dense
            filled
            color="cyan"
            label="Barcode"
            solo
            flat
            class="pl-2 width-100"
            readonly
            v-model.trim="barcode"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <label class="custom-form-label">Tax Preference</label>
          <v-radio-group
            class="pt-0 mt-0 mb-2"
            dense
            row
            color="cyan"
            v-model.trim="productCreate.taxable"
          >
            <v-radio label="Taxable" color="cyan" :value="true"></v-radio>
            <v-radio label="Non-Taxable" color="cyan" :value="false"></v-radio>
          </v-radio-group>
        </div>
        <div class="d-flex">
          <v-text-field
            dense
            filled
            color="cyan"
            label="Charges"
            solo
            flat
            :rules="
              productCreate.product_type != 'goods'
                ? [validateRules.required(productCreate.charges, 'Charges')]
                : []
            "
            class="pr-2 width-100"
            type="number"
            min="0"
            max="9999"
            prepend-inner-icon="mdi-currency-usd"
            v-model.trim.number.lazy="productCreate.charges"
            v-on:keypress="limitDecimal($event, productCreate.charges)"
          ></v-text-field>
          <v-autocomplete
            v-if="productCreate.taxable"
            dense
            color="cyan"
            item-color="cyan"
            :items="taxList"
            filled
            label="Tax"
            solo
            flat
            item-text="name"
            item-value="id"
            :rules="[validateRules.required(productCreate.tax, 'Tax')]"
            v-model.trim="productCreate.tax"
            class="pl-2 width-100"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Tax(s) Found.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-else
            :items="taxExemptionList"
            dense
            filled
            color="cyan"
            item-color="cyan"
            label="Tax Exemption"
            solo
            flat
            item-text="name"
            item-value="id"
            :rules="[
              validateRules.required(
                productCreate.tax_exemption,
                'Tax Exemption'
              ),
            ]"
            v-model.trim="productCreate.tax_exemption"
            class="pl-2 width-100"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="'No Tax Exemption(s) Found.'"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>
        <div v-if="getPermission('attachment:create')">
          <label class="custom-form-label width-100"
            >Images
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-2" v-bind="attrs" v-on="on"
                  >mdi-progress-question</v-icon
                >
              </template>
              <span>More than 5 images are not allowed</span>
            </v-tooltip>
          </label>
          <div>
            <FileTemplate
              allowUpload
              isMinDisplay
              :attachments.sync="productCreate.product_images"
              v-on:file:updated="updateImages"
            ></FileTemplate>
          </div>
        </div>
      </v-col>
      <!-- </perfect-scrollbar> -->
    </v-col>
    <v-col
      class="custom-sidebar-container grey lighten-3"
      cols="12"
      md="3"
      sm="3"
    >
      <template v-for="(fieldInfo, indx) in fieldDescriptions">
        <div
          v-if="fieldInfo.field == currentActiveField"
          :key="indx"
          class="help-sidebar"
        >
          <div class="help-sidebar-header">
            <h3 v-if="fieldInfo.title" class="section-header-title">
              {{ fieldInfo.title }}
            </h3>
          </div>
          <p v-if="fieldInfo.description" class="help-sidebar-description">
            {{ fieldInfo.description }}
          </p>
          <h5 v-if="fieldInfo.footer" class="help-sidebar-footer-title">
            {{ fieldInfo.footer }}
          </h5>
          <p v-if="fieldInfo.footer_content" class="help-sidebar-footer-text">
            {{ fieldInfo.footer_content }}
          </p>
        </div>
      </template>
    </v-col>
  </v-layout>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import WarrantyMixin from "@/core/lib//warranty/warranty.mixin";
import ProductMixin from "@/core/plugins/product-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import {
  QUERY,
  DELETE,
  UPDATE_ERROR,
  CLEAR_ERROR,
} from "@/core/services/store/request.module";

export default {
  name: "product-create",
  mixins: [CommonMixin, WarrantyMixin, ProductMixin, ValidationMixin],
  props: {
    productId: {
      type: [Number, String],
      default: null,
    },
    barcodeData: {
      type: String,
      default: null,
    },
    productCreateData: {
      type: Object,
      default: () => {
        return new Object();
      },
    },
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  data() {
    return {
      timeoutLimit: 500,
      timeout: null,
      brandList: new Array(),
      categoryList: new Array(),
      manufacturerList: new Array(),
      supplierList: new Array(),
      taxList: new Array(),
      taxExemptionList: new Array(),
      unitList: new Array(),
      barcode: null,
      productCreate: {
        show_name: true,
        name: null,
        reference: null,
        sku: null,
        upc: null,
        ean: null,
        isbn: null,
        part_number: null,
        hsn_code: null,
        description: null,
        directory: null,
        supplier: null,
        brand: null,
        manufacturer: null,
        category: null,
        unit: null,
        tax: null,
        tax_exemption: null,
        accounting: "none",
        product_type: "service",
        purchase_cost: null,
        selling_cost: null,
        length: null,
        width: null,
        height: null,
        weight: null,
        taxable: false,
        sellable: true,
        confirmed: false,
        track_batch: false,
        manage_stock: true,
        reorder_level: null,
        initial_stock: null,
        charges: null,
        images: [],
        product_images: [],
        warranty: [
          {
            id: null,
            value: null,
            field: null,
          },
        ],
      },
      fieldDescriptions: [
        {
          field: "product_type",
          title: "Product Type",
          description:
            "Select if this item is a physical good or a service you're offering.",
          footer: "Note",
          footer_content:
            "Once you include this items in a transaction, you cannot change its type.",
        },
      ],
    };
  },
  components: {
    FileTemplate,
  },
  watch: {
    barcodeData(param) {
      this.barcode = param;
    },
    productCreateData: {
      deep: true,
      handler(param) {
        this.productCreate = param;
        this.productCreate.show_name = true;
      },
    },
    productCreate: {
      deep: true,
      handler() {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.$emit("saveProduct", _this.productCreate);
        }, _this.timeoutLimit);
      },
    },
    "productCreate.product_type"() {
      this.getOptions();
    },
  },
  methods: {
    updateImages(param) {
      this.productCreate.images = param;
    },
    pushWarranty() {
      this.productCreate.warranty.push({
        id: null,
        value: null,
        field: null,
      });
    },
    removeWarranty(index, id) {
      const _this = this;
      if (id && id > 0) {
        _this.$store
          .dispatch(DELETE, {
            url: "product/" + _this.productId + "/entity",
            data: {
              type: 1,
              entities: [id],
            },
          })
          .then(() => {
            _this.productCreate.warranty.splice(index, 1);
          })
          .catch((error) => {
            _this.logError(error);
          });
      } else {
        _this.productCreate.warranty.splice(index, 1);
      }
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "product/options",
          data: { product_type: _this.productCreate.product_type },
        })
        .then((response) => {
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.brand
          ) {
            _this.brandList = response.data.brand;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.category
          ) {
            _this.categoryList = response.data.category;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.manufacturer
          ) {
            _this.manufacturerList = response.data.manufacturer;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.supplier
          ) {
            _this.supplierList = response.data.supplier;
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.tax
          ) {
            _this.taxList = response.data.tax;
            if (!_this.productCreate.tax) {
              let index = _this.lodash.findIndex(
                response.data.tax,
                function (tax) {
                  return tax.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax = _this.taxList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.taxExemption
          ) {
            _this.taxExemptionList = response.data.taxExemption;
            if (!_this.productCreate.tax_exemption) {
              let index = _this.lodash.findIndex(
                response.data.taxExemption,
                function (taxExemption) {
                  return taxExemption.default;
                }
              );
              if (index >= 0) {
                _this.productCreate.tax_exemption =
                  _this.taxExemptionList[index].id;
              }
            }
          }
          if (
            _this.lodash.isEmpty(response.data) === false &&
            response.data.unit
          ) {
            _this.unitList = response.data.unit;
          }
          if (
            !_this.productId &&
            _this.lodash.isEmpty(response.data) === false &&
            response.data.barcode
          ) {
            _this.barcode = response.data.barcode;
          }

          if (_this.lodash.isEmpty(response.data.barcode_setting) === false) {
            _this.barcodeSetting = response.data.barcode_setting;
            if (!_this.productCreate.description) {
              _this.productCreate.description =
                _this.barcodeSetting.description;
            }
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  mounted() {
    this.getOptions();
  },
};
</script>
